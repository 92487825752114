<template>
  <!--<PageHeader :title="title" :items="items" />-->

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div></div>
        <div class="col-12 mt-2">
          <div>
            <router-link to="/user">
              <button class="btn btn-success btn btn-success rounded-pill mb-2">
                Back
              </button>
            </router-link>
          </div>

          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <h4 class="card-title mb-0">Edit Customer</h4>
          </div>
        </div>

        <div class="card-body">
          <div>
            <div
              v-if="alert"
              role="alert"
              aria-live="polite"
              aria-atomic="true"
              class="alert alert-danger"
            >
              {{ alertText }}
            </div>
            <b-form v-on:submit.prevent="onSubmit" @reset="onReset" v-if="show">
              <b-form-group
                id="input-group-1"
                label="Username:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  :value="this.username"
                  v-model="username"
                  required
                  placeholder="Enter UserName"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Email:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="email"
                  :value="this.email"
                  @keyup="isEmailValid"
                  required
                  placeholder="Enter Email"
                ></b-form-input>
              </b-form-group>

              <div
                v-show="!this.emailValidation"
                class="alert alert-danger"
                role="alert"
              >
                Invalid Email
              </div>

              <b-form-group
                id="input-group-2"
                label="Password:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  type="password"
                  v-model="password"
                  placeholder="Enter Password"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Mobile Number:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="mobile"
                  type="text"
                  required
                  placeholder="Enter Mobile Number"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Postal Code:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="zipcode"
                  @keyup="isZipCodeValid"
                  type="text"
                  required
                  placeholder="Enter post code"
                ></b-form-input>
              </b-form-group>
              <div
                v-show="!this.zipcodeValidation"
                class="alert alert-danger"
                role="alert"
              >
                Invalid Zip Code
              </div>

              <b-form-group
                id="input-group-2"
                label="Address:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="address"
                  type="text"
                  required
                  placeholder="Enter Address"
                ></b-form-input>
              </b-form-group>

              <b-button type="submit" ref="save" variant="primary"
                >Save</b-button
              >
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  data() {
    return {
      email: "",
      id: "",
      mobile: "",
      alertText: "",
      alert: false,
      emailValidation: true,
      zipcodeValidation: true,
      username: "",
      editRecord: "",
      password: "",
      zipcode: "",
      foods: [
        { text: "Select One", value: null },
        "Carrots",
        "Beans",
        "Tomatoes",
        "Corn",
      ],
      show: true,
    };
  },
  mounted() {
    this.editRecord = JSON.parse(localStorage.getItem("editUser"));
    this.username = this.editRecord.username;
    this.email = this.editRecord.email;
    this.mobile = this.editRecord.mobile;
    this.zipcode = this.editRecord.zipcode;
    this.id = this.editRecord.id;
    this.address = this.editRecord.address;
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      if (!this.emailValidation || !this.zipcodeValidation) {
        evt.preventDefault();
        return false;
      }
      try {
        const data = {
          id: this.id,
          first_name: "",
          last_name: "",
          username: this.username,
          email: this.email,
          password: this.password,
          mobile: this.mobile,
          zipcode: this.zipcode,
          address: this.address,
        };
        // const headers = {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // };
        ApiService.post(this.$path + "/update-user", data, {
          // headers,
        }).then((response) => {
          if (response.data.code == 200) {
            localStorage.setItem(
              "alertmessage",
              JSON.stringify("Record has been updated successfully")
            );
            this.$router.push({ name: "user" });
          }
          if (response.data.code == 422) {
            this.alert = true;
            this.alertText = response.data.message;
          }
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    isEmailValid: function isEmailValid() {
      var regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      var responce = regex.test(this.email);
      if (responce) {
        this.emailValidation = true;
      } else {
        this.emailValidation = false;
      }
      return responce;
    },

    isZipCodeValid: function isZipCodeValid() {
      var regex1 = /^([A-Z][A-Ha-hJ-Yj-y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|[Gg][Ii][Rr] ?0[A]{2})$/;
      var responcend = regex1.test(this.zipcode);
      if (responcend) {
        this.zipcodeValidation = true;
      } else {
        this.zipcodeValidation = false;
      }
      return responcend;
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
</style>
